<header id="header" class="sticky-top-slide">
  <!-- Navbar -->
  <nav
    class="primary-menu navbar navbar-expand-lg navbar-dark bg-transparent fw-500"
  >
    <div class="container position-relative">
      <div class="col-auto col-lg-auto">
        <!-- Logo -->
        <a class="logo" href="index.html" title="slap sam">
          <img src="assets/images/logo.png" alt="slap sam" width="139px" />
        </a>
        <!-- Logo End -->
      </div>
      <div class="col col-lg-10 navbar-accordion">
        <button
          class="navbar-toggler ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#header-nav"
        >
          <span></span><span></span><span></span>
        </button>
        <div
          id="header-nav"
          class="collapse navbar-collapse justify-content-end"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" href="#home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#about">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#roadmap">Roadmap</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#collections">Collections</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="#team">Team</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link" href="#faq">FAQ</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="slap-sam" >Slap-sam</a>
            </li>
            
            <li class="align-items-center h-auto mt-2 mt-lg-0 ms-lg-2">
              <span
                class="btn btn-primary rounded-pill d-inline-block"
                data-bs-toggle="offcanvas"
                
                role="button"
                aria-controls="offcanvas"
                (click)="connect()" *ngIf="!isConnected"><span class="me-1"><i class="fas fa-wallet"></i></span>
                Connect</span
              >

              <span
                class="btn btn-primary rounded-pill d-inline-block"
                data-bs-toggle="offcanvas"
                href="#offcanvas"
                role="button"
                aria-controls="offcanvas"
                *ngIf="isConnected"><span class="me-1"><i class="fas fa-wallet"></i></span>
                {{address?.substring(0,4)}}...{{address?.substring(38,42)}}</span
              >
            </li>
            <li class="align-items-center h-auto mt-2 mt-lg-0 ms-lg-3">
              <a class="btn btn-light rounded-pill d-inline-block" href="#"
                ><span class="me-1"><i class="fab fa-discord"></i></span>
                Discord</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <!-- Navbar End -->
</header>
