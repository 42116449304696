<div id="main-wrapper">
  <!-- Header
    ============================ -->

  <!-- Header End -->

  <!-- Connect Wallet - Offcanvas menu
  ====================================== -->
  <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
    <div class="offcanvas-header">
      <button type="button" class="btn-close btn-close-white ms-auto" data-bs-dismiss="offcanvas"
        aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <h4 class="text-white fw-600 text-center mb-4" id="offcanvasLabel">
        <span class="me-1"><i class="fas fa-wallet"></i></span> Connect
        Wallet
      </h4>
      <div class="text-white-50 text-center mb-4">
        Choose how you want to connect. There are several wallet providers.
      </div>
      <div class="list-group connect-wallet">
        <a href="#" class="list-group-item list-group-item-action" aria-current="true">
          <img class="img-fluid d-flex me-2" src="assets/images/wallet/metamask.png" alt="" />

          MetaMask <span class="badge bg-info ms-auto">Popular</span>
        </a>
        <a href="#" class="list-group-item list-group-item-action" aria-current="true">
          <img class="img-fluid d-flex me-2" src="assets/images/wallet/wallet-connect.png" alt="" />
          Wallet Connect
        </a>
        <a href="#" class="list-group-item list-group-item-action" aria-current="true">
          <img class="img-fluid d-flex me-2" src="assets/images/wallet/coinbase.png" alt="" />
          Coinbase
        </a>
        <a href="#" class="list-group-item list-group-item-action" aria-current="true">
          <img class="img-fluid d-flex me-2" src="assets/images/wallet/fortmatic.png" alt="" />
          Fortmatic
        </a>
        <a href="#" class="list-group-item list-group-item-action" aria-current="true">
          <img class="img-fluid d-flex me-2" src="assets/images/wallet/myetherwallet.png" alt="" />
          MyEtherWallet</a>
      </div>
    </div>
  </div>
  <!-- Connect Wallet - Offcanvas menu End -->

  <!-- Content
    ======================== -->
  <div id="content" role="main">
    <!-- Intro
      ========================= -->
    <section id="home">
      <div class="hero-wrap">
        <div class="hero-content section pb-0 d-flex min-vh-100">
          <div class="container py-5 my-auto">
            <div class="row gy-5 g-lg-4">
              <div class="col-md-6 my-auto text-center text-md-start pt-5 pt-md-0 order-2 order-md-1">
                <h1 class="text-17 fw-600 text-white">
                  The Slap Sam<br class="d-none d-md-block" />
                  NFT Collection
                </h1>
                <p class="text-4 text-light mb-4">
                  The Slap sam is a private collection of NFTs
                  unique digital collectibles. Simply dummy text of the
                  printing and typesetting industry quidam interesset his et
                  essent possim iriure.
                </p>
                <a routerLink="/mint" class="btn btn-primary rounded-pill">Mint Now
                  <span class="text-2 ms-2"><i class="fa fa-chevron-right"></i></span></a>
              </div>
              <div class="col-md-6 pb-5 pb-md-0 order-1 order-md-2">
                <!-- Parallax Mouse Move -->
                <div id="scene" data-relative-input="true" class="scene">
                  <div data-depth="0.6" class="ms-5 mt-md-n5">
                    <img class="img-fluid rounded-4 shadow-lg" src="assets/images/441.png" alt="" />
                  </div>
                  <div data-depth="0.3" class="top-50 start-0 ms-n3 mt-md-5">
                    <img class="img-fluid rounded-4 shadow-lg" src="assets/images/4224.png" alt="" />
                  </div>
                  <div data-depth="0.4" class="top-50 start-50 mt-n4">
                    <img class="img-fluid rounded-4 shadow-lg" src="assets/images/4.png" alt="" />
                  </div>
                </div>
                <!-- Parallax Mouse Move End -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Intro end -->

    <!-- Number -->
    <div class="mt-5">
      <div class="container">
        <div class="row g-4">
          <div class="col-6 col-lg-3">
            <div class="hero-wrap py-3">
              <div class="hero-mask rounded-4 opacity-7 bg-dark"></div>
              <div class="hero-content text-center py-4 px-3">
                <h4 class="text-11 text-white fw-600 mb-0">2999</h4>
                <div class="text-4 text-light">Total Items</div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3">
            <div class="hero-wrap py-3">
              <div class="hero-mask rounded-4 opacity-7 bg-dark"></div>
              <div class="hero-content text-center py-4 px-3">
                <h4 class="text-11 text-white fw-600 mb-0">1076</h4>
                <div class="text-4 text-light">Total Owners</div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3">
            <div class="hero-wrap py-3">
              <div class="hero-mask rounded-4 opacity-7 bg-dark"></div>
              <div class="hero-content text-center py-4 px-3">
                <h4 class="text-11 text-white fw-600 mb-0">0.25</h4>
                <div class="text-4 text-light">Floor Price (ETH)</div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3">
            <div class="hero-wrap py-3">
              <div class="hero-mask rounded-4 opacity-7 bg-dark"></div>
              <div class="hero-content text-center py-4 px-3">
                <h4 class="text-11 text-white fw-600 mb-0">445K</h4>
                <div class="text-4 text-light">Volume Traded</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Number end -->

    <!-- About
      ========================== -->
    <section id="about" class="section">
      <div class="container">
        <!-- Heading -->
        <div class="position-relative d-flex text-center mb-5">
          <h2 class="text-24 text-white-50 opacity-1 text-uppercase fw-600 w-100 mb-0">
            About Us
          </h2>
          <p class="text-9 text-white fw-600 position-absolute w-100 align-self-center lh-base mb-0">
            Our Story<span class="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"></span>
          </p>
        </div>
        <!-- Heading end-->

        <div class="row">
          <div class="col-lg-6 position-relative">
            <div class="about-img">
              <div class="img-1">
                <img class="img-fluid rounded-4 shadow-lg" src="assets/images/303.png" alt="" />
              </div>
              <div class="img-2">
                <img class="img-fluid rounded-4 shadow-lg" src="assets/images/924.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 text-center text-lg-start">
            <h2 class="text-12 text-white fw-600 mb-3">
              Welcome to The slap sam Art Studio.
            </h2>
            <p class="text-5 text-white">
              Everyone knows A real story of sam bankman-fried’s FTX crypto exchange. SCAM SBF is unique collection of
              NFT.
            </p>
            <p class="text-light">
              Show your trust over web3 and prove our unity by miniting nft and bang sam.
            </p>
            <a class="btn btn-primary rounded-pill mt-2" href="#join">Join Our Discord
              <span class="text-2 ms-2"><i class="fa fa-chevron-right"></i></span></a>
          </div>
        </div>
      </div>
    </section>
    <!-- About end -->

    <!-- Roadmap
      =========================== -->
    <section id="roadmap" class="section">
      <div class="container">
        <!-- Heading -->
        <div class="position-relative d-flex text-center mb-5">
          <h2 class="text-24 text-white-50 opacity-1 text-uppercase fw-600 w-100 mb-0">
            Timeline
          </h2>
          <p class="text-9 text-white fw-600 position-absolute w-100 align-self-center lh-base mb-0">
            Roadmap<span class="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"></span>
          </p>
        </div>
        <!-- Heading end-->

        <div class="roadmap text-white">
          <!-- 1st -->
          <div class="row gy-3 mb-5">
            <div class="col-md-6">
              <div class="step-pr shadow mx-auto ms-md-auto me-md-5">
                <div class="text-14 fw-600 text-primary me-n2">
                  0<sup class="text-8 fw-300">%</sup>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="text-center text-md-start ms-md-5">
                <p class="text-4 fw-500 d-inline-flex align-items-center mb-2">
                  Q1 - 2022
                  <span class="badge bg-success fw-400 rounded-pill px-3 ms-2">Completed</span>
                </p>
                <h2 class="text-white fw-600 mb-3">For more in depth information, join us on Twitter and Discord and follow along on the News and Twitter channels.</h2>
                <p class="text-light">
                  BANG SBG Club has been under intensive development to bring you a unique experience that makes crypto world aware about SBF’s Scam and punish him on WEB3 model to show unity of Blockchain world. Our road map shows our plans to reach milestones necessary for the success if BANG SAM Club and show the world power of Trustessness. Many more exciting games and fun is on the way…
                </p>
              </div>
            </div>
          </div>

          <!-- 2nd -->
          <div class="row gy-4 mb-5">
            <div class="col-md-6 order-1 order-md-2">
              <div class="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                <div class="text-14 fw-600 text-primary me-n2">
                  10<sup class="text-8 fw-300">%</sup>
                </div>
              </div>
            </div>
            <div class="col-md-6 order-2 order-md-1">
              <div class="text-center text-md-end me-md-5">
                <p class="text-4 fw-500 d-inline-flex align-items-center mb-2">
                  <span class="badge bg-success fw-400 rounded-pill px-3 me-2">Completed</span>
                  Q2 - 2022
                </p>
                <h2 class="text-white fw-600 mb-3">Welcome to the BANG SBF Official website </h2>
                <p class="text-light">
                  Minting of first 1000 NFT’s First 1000 NFT will be FREE to mint.Minting of Next 1000 NFT’s and So on with Hiking price model to give more benefits to early birds.
                </p>
              </div>
            </div>
          </div>

          <!-- 3rd -->
          <div class="row gy-4 mb-5">
            <div class="col-md-6">
              <div class="step-pr shadow mx-auto ms-md-auto me-md-5">
                <div class="text-14 fw-600 text-primary me-n2">
                  30<sup class="text-8 fw-300">%</sup>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="text-center text-md-start ms-md-5">
                <p class="text-4 fw-500 d-inline-flex align-items-center mb-2">
                  Q3 - 2022
                  <span class="badge bg-success fw-400 rounded-pill px-3 ms-2">Completed</span>
                </p>
                <h2 class="text-white fw-600 mb-3">Start Starvation Mode Program</h2>
                <p class="text-light">
                  Growing community before Launch of SLAP SBF game
                </p>
              </div>
            </div>
          </div>

          <!-- 4th -->
          <div class="row gy-4 mb-5">
            <div class="col-md-6 order-1 order-md-2">
              <div class="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                <div class="text-14 fw-600 text-primary me-n2">
                  40<sup class="text-8 fw-300">%</sup>
                </div>
              </div>
            </div>
            <div class="col-md-6 order-2 order-md-1">
              <div class="text-center text-md-end me-md-5">
                <p class="text-4 fw-500 d-inline-flex align-items-center mb-2">
                  <span class="badge bg-warning text-dark fw-400 rounded-pill px-3 me-2">In Progress</span>
                  Q4 - 2022
                </p>
                <h2 class="text-white fw-600 mb-3">Launching of SLAP SBF Game and Win real money ( Exclusive DAPP to Punish SBF)</h2>
                <p class="text-light">
                  
                </p>
              </div>
            </div>
          </div>

          <!-- 5th -->
          <div class="row gy-4 mb-5">
            <div class="col-md-6">
              <div class="step-pr shadow mx-auto ms-md-auto me-md-5">
                <div class="text-14 fw-600 text-primary me-n2">
                  50<sup class="text-8 fw-300">%</sup>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="text-center text-md-start ms-md-5">
                <p class="text-4 fw-500 d-inline-flex align-items-center mb-2">
                  Q5 - 2023
                  <span class="badge bg-warning text-dark fw-400 rounded-pill px-3 ms-2">In Progress</span>
                </p>
                <h2 class="text-white fw-600 mb-3">Free minting of NEW Exclusive collection of BANG Caroline to BANG SBF Holders</h2>
                
              </div>
            </div>
          </div>

          <!-- 6th -->
          <div class="row gy-4 mb-5">
            <div class="col-md-6 order-1 order-md-2">
              <div class="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                <div class="text-14 fw-600 text-primary me-n2">
                  60<sup class="text-8 fw-300">%</sup>
                </div>
              </div>
            </div>
            <div class="col-md-6 order-2 order-md-1">
              <div class="text-center text-md-end me-md-5">
                <p class="text-4 fw-500 d-inline-flex align-items-center mb-2">
                  <span class="badge bg-warning text-dark fw-400 rounded-pill px-3 me-2">In Progress</span>
                  Q6 - 2023
                </p>
                <h2 class="text-white fw-600 mb-3">Launching of Limited Legendary SBF Collection to only NFT and Collectables holders</h2>
                
              </div>
            </div>
          </div>
          <div class="row gy-4 mb-5">
            <div class="col-md-6">
              <div class="step-pr shadow mx-auto ms-md-auto me-md-5">
                <div class="text-14 fw-600 text-primary me-n2">
                  70<sup class="text-8 fw-300">%</sup>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="text-center text-md-start ms-md-5">
                <p class="text-4 fw-500 d-inline-flex align-items-center mb-2">
                  Q7 - 2023
                  <span class="badge bg-warning text-dark fw-400 rounded-pill px-3 ms-2">In Progress</span>
                </p>
                <h2 class="text-white fw-600 mb-3">Kick and Punch SBF Game to win more Real money </h2>
                
              </div>
            </div>
          </div>
          <div class="row gy-4 mb-5">
            <div class="col-md-6 order-1 order-md-2">
              <div class="step-pr alternate shadow mx-auto me-md-auto ms-md-5">
                <div class="text-14 fw-600 text-primary me-n2">
                  80<sup class="text-8 fw-300">%</sup>
                </div>
              </div>
            </div>
            <div class="col-md-6 order-2 order-md-1">
              <div class="text-center text-md-end me-md-5">
                <p class="text-4 fw-500 d-inline-flex align-items-center mb-2">
                  <span class="badge bg-warning text-dark fw-400 rounded-pill px-3 me-2">In Progress</span>
                  Q8 - 2023
                </p>
                <h2 class="text-white fw-600 mb-3">Launching of Screw her game for Caroline NFT holders ( Slap her ass to win more )</h2>
                
              </div>
            </div>
          </div>
          <div class="row gy-4 mb-5">
            <div class="col-md-6">
              <div class="step-pr shadow mx-auto ms-md-auto me-md-5">
                <div class="text-14 fw-600 text-primary me-n2">
                  90<sup class="text-8 fw-300">%</sup>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="text-center text-md-start ms-md-5">
                <p class="text-4 fw-500 d-inline-flex align-items-center mb-2">
                  Q9 - 2023
                  <span class="badge bg-warning text-dark fw-400 rounded-pill px-3 ms-2">In Progress</span>
                </p>
                <h2 class="text-white fw-600 mb-3">MTGOX Collection launch</h2>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Roadmap end -->

    <!-- Collections
      ============================= -->
    <section id="collections" class="section">
      <div class="container">
        <!-- Heading -->
        <div class="position-relative d-flex text-center mb-5">
          <h2 class="text-24 text-white-50 opacity-1 text-uppercase fw-600 w-100 mb-0">
            Showcase
          </h2>
          <p class="text-9 text-white fw-600 position-absolute w-100 align-self-center lh-base mb-0">
            Our Collections<span
              class="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"></span>
          </p>
        </div>
        <!-- Heading end-->
      </div>

      <!-- Carousel -->
      <div class="owl-carousel owl-theme" data-loop="true" data-dots="false" data-nav="false" data-autoplay="true"
        data-autoplaytimeout="3000" data-smartspeed="3000" data-slidetransition="linear" data-center="true"
        data-margin="25" data-stagepadding="0" data-slideby="1" data-items-xs="3" data-items-sm="4" data-items-md="4"
        data-items-lg="5">
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-1.jpg" alt="" />
        </div>
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-5.jpg" alt="" />
        </div>
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-3.jpg" alt="" />
        </div>
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-6.jpg" alt="" />
        </div>
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-4.jpg" alt="" />
        </div>
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-2.jpg" alt="" />
        </div>
      </div>
      <!-- Carousel End -->

      <!-- Carousel Reverse -->
      <div class="owl-carousel owl-theme" data-rtl="true" data-loop="true" data-dots="false" data-nav="false"
        data-autoplay="true" data-autoplaytimeout="3000" data-smartspeed="3000" data-slidetransition="linear"
        data-center="true" data-margin="25" data-stagepadding="0" data-slideby="1" data-items-xs="3" data-items-sm="4"
        data-items-md="4" data-items-lg="5">
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-7.jpg" alt="" />
        </div>
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-8.jpg" alt="" />
        </div>
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-9.jpg" alt="" />
        </div>
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-10.jpg" alt="" />
        </div>
        <div class="item">
          <img class="img-fluid rounded-4" src="assets/images/collections/item-11.jpg" alt="" />
        </div>
      </div>
      <!-- Carousel Reverse End -->

      <!-- Button -->
      <div class="container text-center mt-5">
        <a class="btn btn-primary rounded-pill mt-2" href="collections.html">View Collections
          <span class="text-2 ms-2"><i class="fa fa-chevron-right"></i></span></a>
      </div>
      <!-- Button End-->
    </section>
    <!-- Collections end -->

    <!-- Team
      ============================ -->
    
    <!-- Team end -->

    <!-- FAQ
      ============================ -->
    <section id="faq" class="section">
      <div class="container">
        <!-- Heading -->
        <div class="position-relative d-flex text-center mb-5">
          <h2 class="text-24 text-white-50 opacity-1 text-uppercase fw-600 w-100 mb-0">
            Questions
          </h2>
          <p class="text-9 text-white fw-600 position-absolute w-100 align-self-center lh-base mb-0">
            Frequently Asked Questions<span
              class="heading-separator-line border-bottom border-3 border-primary d-block mx-auto"></span>
          </p>
        </div>
        <!-- Heading end-->
        <div class="row gy-5">
          <div class="col-lg-6 order-1 order-lg-0">
            <!-- Accordion Start -->
            <div class="accordion accordion-flush accordion-dark" id="faqTopics">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                    Why get bang SBF club ?
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne"
                  data-bs-parent="#faqTopics">
                  <div class="accordion-body">
                    <p class="mb-0">
                      MINT NFT and put him into starvation mode.Our exclusive games (SLAP sam,bang sam,kichsam) will
                      give NFT holders real cash rewards and valuable collectables.
                    </p>
                    <p class="mb-0">
                      Players will get exclusives gifts and collectables. </p>
                    <p class="mb-0">Do you think sam bankman-fried’s ftx crypto exchange cheated on whole blockchain and
                      web3 community ?
                      Of course, sam and whole ftx team is responsible for this crypto crash and breaking faith.SBF
                      built his crypto empire on a pile of quicksand and based on decentralisation but </p>

                      <p class="mb-0">
                        Liberal us media has been portraying sam bankman-fried as a modern-day robinhood. That did not change much even as his empire collapsed, vaporising billions of dollars, money that belonged to ordinary people albeit with extraordinary greed. He is still a good boy. You will see why. Read on. But what lesson, if any, is there for indians?
                      </p>

                      <p class="mb-0">The real danger from the saga that is sweeping through the crypto world is not the financial losses incurred by a few ftx traders & investors. Given the secretive ways the crypto world operates,how the CEX cheating and using people’s funds for own benefits. We will never know how much community lost in that scam.
                        Its not only about ftx community its about whole crypto market capital and innocent retail investors.
                        In case you are not familiar, it is universally agreed by anyone with any sense and integrity that what sbf was doing was clear fraud and a ponzi scheme.
                        If you want to support and stand against SBF join Club and MINT BANG SBF Club and punish him the way you wants.</p>
                      

                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    What is Starvation mode ?
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                  data-bs-parent="#faqTopics">
                  <div class="accordion-body">
                    <p>
                      Starvation mode is a state in which Bang SBF Club NFT will 
Scan Sam will be in starvation mode will begin to accrue rewards and unlock additional benefits. So let‘s put SBF NFT into Starvation Mode.
                    </p>
                    <p class="mb-0">
                      <b>Note </b> : only starvation mode NFTs will be eligible for Rewards and Benefits.
                    </p>

                    <p> <b>Benefit Note</b>  : Put sam in to starvation mode for minimum 7 days to bet extra valuable collatable. Ex. Kife,Stone,Punch Gloves ,Shoe,Sitck,Face Mask,Hammer etc. </p>
                    <p>  <b>Slap Sam Game </b>
                      :
                      Liberal us media has been portraying sam bankman-fried as a modern-day robinhood but everyone knows that how sam gets traded.Slap SBF game represents the unity of Blockchain and WEB3 believers to stand against fraud and punish sam.Every WEB3 user can slap sam everyday forver because we have designed DAPP based game.</p>

                      <p>All the NFT’s which are in starvation mode will receive daily rewards based on total Slaps.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    Who can Play Game ?
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                  data-bs-parent="#faqTopics">
                  <div class="accordion-body">
                    <p class="mb-0">
                      Anyone who is having Polygone chain account.
                    </p>
                     
                    <p> <b>Players Benefits :</b> </p>
                    <ul>
                      <li>Daily Reward 10 Matic : Highest and Fastest slap will get 10 Matic.</li>
                     <li>Daily Lucky Reward : Random user will get daily 10 Matic.</li>
                     <li> <b>Note</b> : All rewards distribution is daily. User need to claim it on same day. Reward distribution is DAPP based no manual interaction.</li>

                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    Why Blockchain,Crypto and WEB3 community wants to BANG SBF ?
                  </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
                  data-bs-parent="#faqTopics">
                  <div class="accordion-body">
                    <p class="mb-0">
                      Here,PUT Twitter , Blog , News screenshots to create more awareness about FTX’s fraud.
                    </p>
                    
                    <ul>
                      <li>Trust the Trust lessness :</li>
                      <li>NFT Contract Address :NFT Minting Contract :</li>
                      <li>NFT 50% Developers share address :NFT 50% Reward share address:SLAP SBF Game contract : Coming soon
                        Valuable collectable contract :</li>
                        <li>Reward distribution contract :</li>
                        <li>Daily Slap Count :Daily total slaps will be counted and respective rewards distributed to Starvation Mode NFT Owners..</li>
                        <li>Official Website :Official Twitter :Official Instagram :</li>

                    </ul>

                  </div>
                </div>
              </div>
              <!-- <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                    Where we can buy and sell NFts ?
                  </button>
                </h2>
                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive"
                  data-bs-parent="#faqTopics">
                  <div class="accordion-body">
                    <p class="mb-0">
                      Anim pariatur cliche reprehenderit, enim eiusmod high
                      life accusamus terry richardson ad squid. Food truck
                      quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                      tempor, sunt aliqua put a bird on it squid
                      single-origin coffee nulla assumenda shoreditch et.
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident. Ad vegan
                      excepteur butcher vice lomo.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    Where does my NFT go after I purchase ?
                  </button>
                </h2>
                <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix"
                  data-bs-parent="#faqTopics">
                  <div class="accordion-body">
                    <p class="mb-0">
                      Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                      wolf moon tempor, sunt aliqua put a bird on it squid
                      single-origin coffee nulla assumenda shoreditch et.
                      Nihil anim keffiyeh helvetica, craft beer labore wes
                      anderson cred nesciunt sapiente ea proident.
                    </p>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- Accordion End -->
          </div>
          <div class="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1">
            <img class="img-fluid" src="assets/images/faq.png" title="FAQ" alt="faq" />
          </div>
        </div>
      </div>
    </section>
    <!-- FAQ end -->

    <!-- Blog Articles
      ============================ -->
    
    <!-- Blog Articles end -->

    <!-- Join
      ============================== -->
    <section id="join" class="section pb-0">
      <div class="container">
        <!-- Heading -->
        <div class="position-relative d-flex text-center">
          <h2 class="text-24 text-white-50 opacity-1 text-uppercase fw-600 w-100 lh-1 mb-0 mb-n1">
            don’t miss
          </h2>
        </div>
        <!-- Heading end-->

        <div class="bg-primary text-center shadow-lg bgImage rounded-4 p-5 mt-n3">
          <!-- <div class="text-15 text-white lh-1 mb-2">
            <i class="fab fa-discord"></i>
          </div>
          <h3 class="text-11 text-white fw-600 mb-3">Join Our Community</h3>
          <a class="btn btn-light rounded-pill" href="#">Join Discord</a> -->
        </div>
      </div>
    </section>
    <!-- Join end -->
  </div>
  <!-- Content end -->

  <!-- Footer
    ============================== -->

  <!-- Footer end -->
</div>
<!-- Document Wrapper end -->

<!-- Back to Top
  ============================== -->
<a id="back-to-top" data-bs-toggle="tooltip" title="Back to Top" href="javascript:void(0)"><i
    class="fa fa-chevron-up"></i></a>

<!-- Security Modal
  ================================== -->
<div id="security" class="modal fade" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Security</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-4">
        <p>
          Simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled
          it to make a type specimen book.
        </p>
        <ul class="lh-lg">
          <li>
            Lisque persius interesset his et, in quot quidam persequeris
            vim, ad mea essent possim iriure.
          </li>
          <li>
            Quidam lisque persius interesset his et, Lisque persius
            interesset his et, in quot quidam persequeris vim, ad mea essent
            possim iriure.
          </li>
          <li>
            In quot quidam persequeris vim, ad mea essent possim iriure.
            Quidam lisque persius interesset his et.
          </li>
          <li>
            Quidam lisque persius interesset his et, Lisque persius
            interesset his et.
          </li>
          <li>
            Interesset his et, Lisque persius interesset his et, in quot
            quidam persequeris vim, ad mea essent possim iriure.
          </li>
          <li>
            Persius interesset his et, Lisque persius interesset his et, in
            quot quidam persequeris vim, ad mea essent possim iriure.
          </li>
          <li>
            Quot quidam persequeris vim Quidam lisque persius interesset his
            et, Lisque persius interesset his et, in quot quidam persequeris
            vim, ad mea essent possim iriure.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Security Modal End -->

<!-- Terms Modal
  ================================== -->
<div id="terms" class="modal fade" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Terms</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-4">
        <p>
          Simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled
          it to make a type specimen book. It has survived not only five
          centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </p>
        <h3 class="mb-3 mt-4 mt-4">Terms of Use</h3>
        <p>
          It has survived not only five centuries, but also the leap into
          electronic typesetting, remaining essentially unchanged. Simply
          dummy text of the printing and typesetting industry.
        </p>
        <h5 class="text-4 mt-4">
          Part I – Information slap sam collects and controls
        </h5>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since
          the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
        <h5 class="text-4 mt-4">
          Part II – Information that slap sam processes on your behalf
        </h5>
        <p>
          Lorem Ipsum has been the industry's standard dummy text ever since
          the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
        <h5 class="text-4 mt-4">Part III – General</h5>
        <p>
          It has survived not only five centuries, but also the leap into
          electronic typesetting, remaining essentially unchanged. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled
          it to make a type specimen book.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- Terms Modal End -->

<!-- Privacy Modal
  ================================== -->
<div id="privacy" class="modal fade" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Privacy Policy</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-4">
        <p>
          Simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled
          it to make a type specimen book.
        </p>
        <ul class="lh-lg">
          <li>
            Lisque persius interesset his et, in quot quidam persequeris
            vim, ad mea essent possim iriure.
          </li>
          <li>
            Quidam lisque persius interesset his et, Lisque persius
            interesset his et, in quot quidam persequeris vim, ad mea essent
            possim iriure.
          </li>
          <li>
            In quot quidam persequeris vim, ad mea essent possim iriure.
            Quidam lisque persius interesset his et.
          </li>
          <li>
            Quidam lisque persius interesset his et, Lisque persius
            interesset his et.
          </li>
          <li>
            Interesset his et, Lisque persius interesset his et, in quot
            quidam persequeris vim, ad mea essent possim iriure.
          </li>
          <li>
            Persius interesset his et, Lisque persius interesset his et, in
            quot quidam persequeris vim, ad mea essent possim iriure.
          </li>
          <li>
            Quot quidam persequeris vim Quidam lisque persius interesset his
            et, Lisque persius interesset his et, in quot quidam persequeris
            vim, ad mea essent possim iriure.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>