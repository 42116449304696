
<div
class="offcanvas offcanvas-end text-bg-dark"
tabindex="-1"
id="offcanvas"
aria-labelledby="offcanvasLabel"
>
<div class="offcanvas-header">
  <button
    type="button"
    class="btn-close btn-close-white ms-auto"
    data-bs-dismiss="offcanvas"
    aria-label="Close"
  ></button>
</div>
<div class="offcanvas-body">
  <h4 class="text-white fw-600 text-center mb-4" id="offcanvasLabel">
    <span class="me-1"><i class="fas fa-wallet"></i></span> Connect
    Wallet
  </h4>
  <div class="text-white-50 text-center mb-4">
    Choose how you want to connect. There are several wallet providers.
  </div>
  <div class="list-group connect-wallet">
    <a
      href="#"
      class="list-group-item list-group-item-action"
      aria-current="true"
    >
      <img
        class="img-fluid d-flex me-2"
        src="images/wallet/metamask.png"
        alt=""
      />
      MetaMask <span class="badge bg-info ms-auto">Popular</span>
    </a>
    <a
      href="#"
      class="list-group-item list-group-item-action"
      aria-current="true"
    >
      <img
        class="img-fluid d-flex me-2"
        src="images/wallet/wallet-connect.png"
        alt=""
      />
      Wallet Connect
    </a>
    <a
      href="#"
      class="list-group-item list-group-item-action"
      aria-current="true"
    >
      <img
        class="img-fluid d-flex me-2"
        src="images/wallet/coinbase.png"
        alt=""
      />
      Coinbase
    </a>
    <a
      href="#"
      class="list-group-item list-group-item-action"
      aria-current="true"
    >
      <img
        class="img-fluid d-flex me-2"
        src="images/wallet/fortmatic.png"
        alt=""
      />
      Fortmatic
    </a>
    <a
      href="#"
      class="list-group-item list-group-item-action"
      aria-current="true"
    >
      <img
        class="img-fluid d-flex me-2"
        src="images/wallet/myetherwallet.png"
        alt=""
      />
      MyEtherWallet</a
    >
  </div>
</div>
</div>
<!-- Connect Wallet - Offcanvas menu End -->

<!-- Content
======================== -->
<div id="content" role="main">
<div class="section mt-5 pb-0">
  <div class="container pt-5">
    <div class="row g-5">
      <!-- Item Image -->
      <div class="col-lg-6 text-center">
        <img
          class="img-fluid rounded-5"
          src="assets/images/mintPage.gif"
          alt=""
        />
      </div>
      <!-- Item Image end -->

      <!-- Item Details -->
      <div class="col-lg-6 text-center text-lg-start">
        <h1 class="text-light text-5 mb-4">Potocnik #312</h1>
        <h2 class="text-10 text-white fw-600 mb-4">
          Public Mint is
          <span class="bg-success rounded-4 px-3">Live</span>
        </h2>

        <!-- Public Mint end in -->
        <div class="row gx-3 align-items-center mb-3">
          <div class="col-12 col-lg-auto text-light">Mint end in:</div>
          <div class="col-12 col-lg-auto">
            <div
              class="countdown2 d-inline-block text-white text-7 fw-600"
              data-countdown-end="2022/11/18 12:00:00"
            ></div>
          </div>
        </div>
      
        <p
          class="border border-secondary border-opacity-50 rounded-pill d-inline-block px-3 text-uppercase text-light"
        >
          5 Max Per Wallet
        </p>

        <!-- Price -->
        <div
          class="row justify-content-center justify-content-lg-start mb-4"
        >
          <div class="col-auto col-lg-4">
            <p class="text-light mb-0">Price:</p>
            <div class="text-7 text-white fw-600">2.69 ETH</div>
          </div>
          <div class="col-auto col-lg-4">
            <p class="text-light mb-0">Remaining:</p>
            <div class="text-7 text-white fw-600">634/899</div>
          </div>
        </div>

        <!-- Quantity -->
        <div
          class="row g-2 align-items-center justify-content-center justify-content-lg-start mb-4"
        >
          <div class="col-12 col-sm-auto text-light">Quantity:</div>
          <div class="col-auto col-sm-4">
            <div class="input-group form-dark">
              <button
                type="button"
                class="input-group-text bg-transparent border-0 text-light text-5"
                data-value="decrease"
                data-target="#hotels-rooms"
                data-toggle="spinner"
                (click)="setnumberOfNfts(numberOfNfts-1)"
              >
                -
              </button>
              <input
                type="text"
                data-ride="spinner"
                id="hotels-rooms"
                class="form-control text-center bg-trnsparent rounded-4"
                value="{{numberOfNfts}}"
                data-min="1"
              />
              <button
                type="button"
                class="input-group-text bg-transparent border-0 text-light text-5"
                data-value="increase"
                data-target="#hotels-rooms"
                data-toggle="spinner"
                (click)="setnumberOfNfts(numberOfNfts+1)"
              >
                +
              </button>
            </div>
          </div>
        </div>

        <!-- Button -->
        <div class="d-grid">
          <div (click)="buyNft()" class="btn btn-primary btn-lg rounded-pill" href="#"
            >Mint Now</div
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Join
============================== -->
<section id="join" class="section pb-0">
  <div class="container">
    <!-- Heading -->
    <div class="position-relative d-flex text-center">
      <h2
        class="text-24 text-white-50 opacity-1 text-uppercase fw-600 w-100 lh-1 mb-0 mb-n1"
      >
        don’t miss
      </h2>
    </div>
    <!-- Heading end-->

    <div class="bg-primary text-center shadow-lg rounded-4 p-5 mt-n3">
      <div class="text-15 text-white lh-1 mb-2">
        <i class="fab fa-discord"></i>
      </div>
      <h3 class="text-11 text-white fw-600 mb-3">Join Our Community</h3>
      <a class="btn btn-light rounded-pill" href="#">Join Discord</a>
    </div>
  </div>
</section>
<!-- Join end -->
</div>