import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { MintComponent } from './mint/mint.component';
import { SlapSamComponent } from './slap-sam/slap-sam/slap-sam.component';

const routes: Routes = [
 
  {path:'header', component:HeaderComponent},
  {path:'footer', component:FooterComponent},
  {path:'home', component:HomeComponent},
  {path:'mint', component:MintComponent},
  {path : 'slap-sam', component:SlapSamComponent },
  {path : '' ,redirectTo :'/home' , pathMatch:'full'} ,
   
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
