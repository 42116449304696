import { Component, OnInit } from '@angular/core';
import { ethers } from 'ethers';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../core/services/global.service';

@Component({
  selector: 'app-mint',
  templateUrl: './mint.component.html',
  styleUrls: ['./mint.component.scss']
})
export class MintComponent implements OnInit {
  address!: string | null;
  isConnected = false;
  balance: any;
  numberOfNfts = 1;
  mintingFee:any = [
    {
      start : 1,
      end :1000,
      price : 0
    },
    {
      start : 1001,
      end :2000,
      price : 0.01
    },
    {
      start : 2001,
      end :3000,
      price : 0.02
    },
    {
      start : 3001,
      end :4000,
      price : 0.04
    },
    {
      start : 4001,
      end :5000,
      price : 0.08
    },
    {
      start : 5001,
      end :6000,
      price : 0.16
    },
    {
      start : 6001,
      end :7000,
      price : 0.32
    },
    {
      start : 7001,
      end :8000,
      price : 0.64
    },
    {
      start : 8001,
      end :9000,
      price : 1.28
    },
    {
      start : 9001,
      end :10000,
      price : 0.09
    },
  ]
  constructor(private cs: GlobalService,private toastr: ToastrService) {}
  ngOnInit(): void {
    this.getAccount()
  }

  async getAccount()
  {
    await this.cs.connectContract();
    this.cs.getWalletObs().subscribe( async (res) => {
      if (this.cs.isValidAddress(res)) {
        this.address = res;
        this.isConnected = true;
      }else{
        this.isConnected = false;
      }
    });


  }

  setnumberOfNfts(no:number)
  {
    this.numberOfNfts=no;
    if(this.numberOfNfts>5){
      this.numberOfNfts = 5;
    }
    if(this.numberOfNfts<=0){
      this.numberOfNfts = 1;
    }
  }

  async buyNft()
  {
    try{
      debugger
      let price = ethers.utils.parseEther(await (await this.getPricing()).toString());
      debugger
      await this.cs.slapsamContractRef.buyNft(this.numberOfNfts,{value : price});
    }
    catch(e:any)
    { 
      this.toastr.error(e)
        console.log(e)
    }
  }

  async getPricing()
  {
    let nftId = await this.cs.slapsamContractRef.nftId();
    let amount = 0;
    for(let i=0;i<this.numberOfNfts;i++){

    for(let j=0;j<this.mintingFee.length;j++)
    {
      var element = this.mintingFee[j];
      if(nftId>=element.start && nftId <= element.end)
      {

        amount += element.price;
        console.log(amount)
        nftId++;
        break;
      }
    }
  }
    return amount;
  }

}
