import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/core/services/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  address!: string | null;
  isConnected = false;
  balance: any;
  constructor(private cs: GlobalService) {}

 async ngOnInit() {
  this.connect();
    this.cs.getWalletObs().subscribe( async (res) => {
      if (this.cs.isValidAddress(res)) {
        this.address = res;
        this.isConnected = true;
      }else{
        this.isConnected = false;
      }
    });

    this.address = localStorage.getItem('address');

  }

  async connect() {
    await this.cs.connectContract();
  }
}
