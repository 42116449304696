import { Injectable, OnInit } from '@angular/core';
import { ethers } from "ethers";
import { Observable, BehaviorSubject } from 'rxjs';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { environment } from 'src/environments/environment';


function _window(): any {
  return window;
}



declare let require: any;
// declare let window: any;

const slapsamAbi = require('../../../assets/abi/slamsam.abi.json');
const stakingAbi = require('../../../assets/abi/slamsam.abi.json');
// const insuranceAbi = require('./../assets/insurance.json');
const provider = new WalletConnectProvider({
  infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
  rpc: {
    97: 'https://data-seed-prebsc-1-s1.binance.org:8545'
  },
  chainId: 97
});



@Injectable({
  providedIn: 'root'
})
export class GlobalService implements OnInit{
  _account: any;
  provider: any;
  signer: any;

  public walletDetails$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  slapsamContractRef!: ethers.Contract;
  stakingContractRef!: ethers.Contract;

  constructor() {

  }
  ngOnInit(): void {

  }
  //to get windows object....
  get nativeWindow(): any {
    return _window();
  }

  getWalletObs(): Observable<any> {
    return this.walletDetails$.asObservable();
  }

  setWalletObs(profile: any) {
    this.walletDetails$.next(profile);
  }

  getProvider(){
    return this.provider;
  }

  async connectContract() {
    if ((typeof this.nativeWindow.ethereum) !== undefined && (typeof this.nativeWindow.ethereum) != undefined && (typeof this.nativeWindow.ethereum) != 'undefined') {
      await this.nativeWindow.ethereum.request({ method: 'eth_requestAccounts' });
      this.provider = new ethers.providers.Web3Provider(this.nativeWindow.ethereum);
      await this.getAccountAddress();
      localStorage.setItem('wallet', '1');

      this.nativeWindow.ethereum.on("accountsChanged", async (accounts: string[]) => {
        console.log('accounts==>',accounts[0]);
        await this.getAccountAddress();
      });
  
    } else {
      alert('meta mask not install on this system please install it.')
    }
  }

  metamskDisconnect() {
    this.nativeWindow.ethereum.on("accountsChanged", (accounts: string[]) => {
      if (accounts.length == 0) {
        localStorage.setItem('wallet', '0');
      }
    });

  }

  async WalletConnect() {
      try {
        await provider.enable().then(
          (e: any) => {
            console.log(e);
          }, (e: any) => {
            console.log(e);
          }
        );
  
        this.provider = new ethers.providers.Web3Provider(provider);
        localStorage.setItem('wallet', "2");
        var address01 = await this.getAccountAddress();

        this.setWalletObs(address01);
  
        provider.on('accountsChanged', async (accounts: string[]) => {
          console.log(accounts);
          address01 = await this.getAccountAddress();
        });
    
        provider.on('disconnect', (code: number, reason: string) => {
          location.reload();
        });
        provider.on('networkChanged', (code: number, reason: string) => {
       
        });
    
        return address01;
    
      } catch (error) {
        
      }
  }

  async getAccountAddress() {
    this.signer = await this.provider.getSigner();
    this._account = await this.signer.getAddress();
    var network = await this.provider.getNetwork();
    localStorage.setItem('address', this._account);
    if(network.chainId === environment.chainId){
      this.slapsamContractRef = new ethers.Contract(
        environment.slapsam,
        slapsamAbi,
        this.signer
      );
      this.stakingContractRef = new ethers.Contract(
        environment.staking,
        stakingAbi,
        this.signer
      );
    }
    
    this.setWalletObs(this._account);
    return this._account;
  }
  async signMessage(message: any) {
    this.signer = await this.provider.getSigner();
    var signature = await this.signer.signMessage(message);
    return { status: true, signature: signature };
  }


  async getAccount() {
    return this._account;
  }

  public isValidAddress(address: any) {
    return ethers.utils.isAddress(address);
  }

}
