
  <footer id="footer" class="section footer-dark my-4">
    <div class="container">
      <!-- Social Icon -->
      <ul
        class="social-icons social-icons-dark social-icons-lg justify-content-center mb-4"
      >
        <li class="social-icons-discord">
          <a
            data-bs-toggle="tooltip"
            href="http://www.discord.com"
            target="_blank"
            title="Discord"
            ><i class="fab fa-discord"></i
          ></a>
        </li>
        <li class="social-icons-facebook">
          <a
            data-bs-toggle="tooltip"
            href="http://www.facebook.com"
            target="_blank"
            title="Facebook"
            ><i class="fab fa-facebook-f"></i
          ></a>
        </li>
        <li class="social-icons-twitter">
          <a
            data-bs-toggle="tooltip"
            href="https://twitter.com"
            target="_blank"
            title="Twitter"
            ><i class="fab fa-twitter"></i
          ></a>
        </li>
        <li class="social-icons-instagram">
          <a
            data-bs-toggle="tooltip"
            href="http://www.dribbble.com"
            target="_blank"
            title="Instagram"
            ><i class="fab fa-instagram"></i
          ></a>
        </li>
        <li class="social-icons-telegram">
          <a
            data-bs-toggle="tooltip"
            href="http://www.telegram.com"
            target="_blank"
            title="Telegram"
            ><i class="fab fa-telegram-plane"></i
          ></a>
        </li>
      </ul>
      <!-- Copyright Text -->
      <p class="text-center mb-4">
        Copyright © 2022 <a href="#" class="fw-500">slap sam</a>. All Rights
        Reserved.
      </p>
      <!-- Other Links -->
      <ul class="nav nav-sm justify-content-center">
        <li class="nav-item">
          <a
            class="nav-link"
            data-bs-toggle="modal"
            data-bs-target="#security"
            href="#"
            >Security</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            data-bs-toggle="modal"
            data-bs-target="#terms"
            href="#"
            >Terms</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            data-bs-toggle="modal"
            data-bs-target="#privacy"
            href="#"
            >Privacy</a
          >
        </li>
      </ul>
    </div>
  </footer>