
<section class="slap-sam-section" >
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-5">
                <div class="slap-box" >
                    <div class="slap-contain p-4 text-center corners" >
                       <div  >
                        <h3 class="statescount" >0</h3>
                        <p class="statext">Label Counter</p>
                       </div>
                    </div>
                    <div class="slap-contain p-4 text-center corners" >
                        <div>
                         <h3 class="statescount" >0</h3>
                         <p class="statext">Label Counter</p>
                        </div>
                     </div>
                     <div class="slap-contain p-4 text-center corners" >
                        <div>
                         <h3 class="statescount" >0</h3>
                         <p class="statext">Label Counter</p>
                        </div>
                     </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
           
                <div class="col-md-2">
                    <div class="gridsystem">
                        <div class="slap-contain p-4 text-center corners" >
                            <div>
                             <h3 class="statescount" >0</h3>
                             <p class="statext">Label Counter</p>
                            </div>
                         </div>
                         <div class="slap-contain p-4 text-center corners" >
                            <div>
                             <h3 class="statescount" >0</h3>
                             <p class="statext">Label Counter</p>
                            </div>
                         </div>
                    </div>
                </div>
            
            <div class="col-md-5">
                <div class="slap-container" >
                    <img src="assets/images/collections/item-3.jpg" alt="" class="slap-img" >
                </div>
            </div>
            <div class="col-md-2">
               <div class="gridsystem" >
                <div class="slap-contain p-4 text-center corners" >
                    <div>
                     <h3 class="statescount" >0</h3>
                     <p class="statext">Label Counter</p>
                    </div>
                 </div>
                <div class="slap-contain p-4 text-center corners" >
                    <div>
                     <h3 class="statescount" >0</h3>
                     <p class="statext">Label Counter</p>
                    </div>
                 </div>
               </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-5">
                <div class="text-center" >
                    <i class="fa-solid fa-volume-xmark fa-3x mutevlumn"></i>
                    <button class="btn btn-primary slapsbutton" > Remaining Slaps </button>
                </div>
            </div>
        </div>
    </div>
</section>