import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slap-sam',
  templateUrl: './slap-sam.component.html',
  styleUrls: ['./slap-sam.component.scss']
})
export class SlapSamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
